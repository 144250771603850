// extracted by mini-css-extract-plugin
export var adsBody = "style-module--ads-body--52a56";
export var adsButton = "style-module--ads-button--6b8c2";
export var adsButtonContainer = "style-module--ads-button-container--8384c";
export var adsButtonSecondary = "style-module--ads-button-secondary--cd665";
export var adsButtonSecondaryWide = "style-module--ads-button-secondary-wide--16c54";
export var adsButtonWide = "style-module--ads-button-wide--4e43e";
export var adsContainer = "style-module--ads-container--21ccc";
export var adsContent = "style-module--ads-content--55c9a";
export var adsDisclaimer = "style-module--ads-disclaimer--9294b";
export var adsIconContainer = "style-module--ads-icon-container--af5d9";
export var adsOfferHeading = "style-module--ads-offer-heading--97d06";
export var adsTitle = "style-module--ads-title--2a41f";
export var large = "style-module--large--6b6d1";