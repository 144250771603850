// extracted by mini-css-extract-plugin
export var boldText = "style-module--bold-text--c75eb";
export var cardImage = "style-module--card-image--af4dc";
export var cardImageImg = "style-module--card-image-img--012ee";
export var cardRow = "style-module--card-row--19704";
export var cardValuesHeader = "style-module--card-values-header--a0763";
export var description = "style-module--description--ac1b6";
export var getStarted = "style-module--get-started--d8597";
export var getStartedButton = "style-module--get-started-button--befd4";
export var heading = "style-module--heading--99c22";
export var listHeader = "style-module--list-header--edae6";
export var seeDetailsBelow = "style-module--see-details-below--7492a";
export var subHeading = "style-module--sub-heading--bcc90";