import { useMediaQuery } from "@mui/material"
import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import Product from "models/Product.js"
import CreditCardProduct from "models/product-models/CreditCardProduct.js"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import LazyLoad, { forceCheck } from "react-lazyload"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { cardImage, providerImage } from "../../survey/utils.js"
//import CardValues from "../card-values"
import { CardValues } from "../product-values-row"
import { CreditCardOffer as Offer } from "../offer"
import * as TickLines from "../tick-lines"
import * as style from "./style.module.scss"
import CreditCardOffer from "models/offer-models/CreditCardOffer.js"

function makeSchemaOrg(product: Product): JSX.Element {
  return (
    <div>
      {/* Google search haven't implement CreditCard and Reward Program Type, use Product instead */}
      {/* <JsonLd<CreditCard>
        item={{
          "@context": "https://schema.org",
          "@type": "CreditCard",
          alternateName: product.nameLong,
          provider: product.providerName,
          logo: providerImage(product.providerId),
          image: cardImage(product.id),
          feesAndCommissionsSpecification: "$" + product.annualFee.toString() + "p.a.",
          interestRate: product.purchaseRate,
          areaServed: "Australia",

          // CreditCard: "Visa",
          // url: product.offers.landingPageUrl,
          // "cashback": product.offers.cashback,
        }}
      />
      {product.rewardProgramId ? (
        <div>
          <JsonLd<ProgramMembership>
            item={{
              "@context": "https://schema.org",
              "@type": "ProgramMembership",
              programName: product.rewardProgramName,
              membershipPointsEarned: product.pointsPerSpend,
            }}
          />
        </div>
      ) : null} */}

      {/* waiting for confirmation from Anthony */}
      {/* <JsonLd<ProductSchema>
        item={{
          "@context": "https://schema.org",
          "@type": "Product",
          alternateName: product.nameLong,
          provider: product.providerName,
          logo: providerImage(product.providerId),
          image: cardImage(product.id),
          feesAndCommissionsSpecification: "$" + product.annualFee.toString() + "p.a.",
          interestRate: product.purchaseRate,
          areaServed: "Australia",
        }}
      /> */}
    </div>
  )
}

const ProductComponent = ({ product, largeCardImage, learnMore, hideName, mobileProduct, offerFilter, isTablet, useAsterisk, showDisclaimer, isTopPick }: { product: CreditCardProduct; largeCardImage?: boolean; learnMore?: boolean; hideName?: boolean; mobileProduct?: boolean; offerFilter?: string; isTablet?: boolean; useAsterisk?: boolean; showDisclaimer?: boolean; isTopPick?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     forceCheck()
  //   }, 10)
  //   return () => {}
  // }, [])
  setTimeout(() => {
    forceCheck()
  }, 10)

  // Offers
  let offersList: JSX.Element[] = []
  Object.keys(product.offers).forEach((offerId, offerKey) => {
    const offer = product.offers[offerId] as CreditCardOffer
    if (offerFilter) {
      switch (offerFilter) {
        case "0% Balance Transfer":
          if (offer.btRate !== null && offer.btMonths !== null && offer.btRate === 0 && offer.btMonths >= 12) {
            offersList.push(<Offer key={offerId.toString() + "-" + offerKey.toString()} offerJson={product.offers[offerId] as CreditCardOffer} providerName={product.providerName} cardHeading={product.cardHeading} isTablet={isTablet} disclaimer={showDisclaimer} />)
          }
          break
        case "0% Purchase":
          if (offer.introPurchaseRate !== null && offer.introPurchaseMonths !== null && offer.introPurchaseRate === 0 && offer.introPurchaseMonths >= 12) {
            offersList.push(<Offer key={offerId.toString() + "-" + offerKey.toString()} offerJson={product.offers[offerId] as CreditCardOffer} providerName={product.providerName} cardHeading={product.cardHeading} isTablet={isTablet} disclaimer={showDisclaimer} />)
          }
          break
        case "Cash Back":
          if (offer.cashBack !== null && offer.cashBack >= 100) {
            offersList.push(<Offer key={offerId.toString() + "-" + offerKey.toString()} offerJson={product.offers[offerId] as CreditCardOffer} providerName={product.providerName} cardHeading={product.cardHeading} isTablet={isTablet} disclaimer={showDisclaimer} />)
          }
          break
        default:
          offersList.push(<Offer key={offerId.toString() + "-" + offerKey.toString()} offerJson={product.offers[offerId] as CreditCardOffer} providerName={product.providerName} cardHeading={product.cardHeading} isTablet={isTablet} disclaimer={showDisclaimer} />)
      }
    } else {
      offersList.push(<Offer key={offerId.toString() + "-" + offerKey.toString()} offerJson={product.offers[offerId] as CreditCardOffer} providerName={product.providerName} cardHeading={product.cardHeading} isTablet={isTablet} disclaimer={showDisclaimer} />)
    }
  })
  offersList = offersList.sort((a, b) => (a.props.offerJson.active >= b.props.offerJson.active ? 1 : -1)).reverse()

  if (mobileProduct) {
    return (
      <div>
        {makeSchemaOrg(product)}
        <LazyLoad height={400}>
          <div>
            <div style={{ display: "flex", position: "relative", marginBottom: "40px" }}>
              <div style={{ width: "33%" }}>
                <LazyLoadImage src={cardImage(product.id)} title={product.cardHeading} alt={product.cardHeading} style={{ width: "100%", maxHeight: "100%" }} />
              </div>
              <div style={{ width: "33%" }}></div>
              <div style={{ width: "33%" }}>
                <LazyLoadImage src={providerImage(product.providerId)} alt={product.providerName} title={product.providerName} style={{ position: "absolute", right: "0", height: "38px", width: "56px" }} />
              </div>
            </div>
            <TickLines.CreditCard product={product} learnMore={learnMore} isTopPick={isTopPick} />
            <div style={{ width: "100%", height: "88px", marginBottom: "40px" }}>
              <CardValues product={product} mobileProduct isOfferDetails useAsterisk={useAsterisk} />
            </div>
            {offersList}
          </div>
        </LazyLoad>
      </div>
    )
  } else if (isTablet || !isWide) {
    return (
      <div>
        {makeSchemaOrg(product)}
        <LazyLoad height={400}>
          <div className={style.upperContainer}>
            <div style={{ display: "flex", marginTop: "30px" }}>
              {hideName ? null : (
                <Typography.P comp="card-name-bold" style={{ width: "87.54%" }}>
                  <span style={{ lineHeight: "29.23px", fontSize: "18px" }}>{product.nameLong}</span>
                </Typography.P>
              )}
              <div style={{ width: "12.46%" }}>
                <LazyLoadImage src={providerImage(product.providerId)} alt={product.providerName} title={product.providerName} style={{ height: "29.23px", width: "42px", marginLeft: "auto", marginRight: "auto", display: "block" }} />
              </div>
            </div>
            {product.forSale === "GRANDFATHERED" && !hideName ? <div className={style.subHeading}>(no longer for sale)</div> : null}
            {product.forSale === "PAUSED" && !hideName ? <div className={style.subHeading}>(currently not for sale)</div> : null}
            <div className={style.cardRowMobile} style={{ marginBottom: largeCardImage ? "54px" : "40px" }}>
              <div className={largeCardImage ? style.cardImageLarge : style.cardImageMobile}>
                <LazyLoadImage src={cardImage(product.id)} title={product.cardHeading} alt={product.cardHeading} className={style.cardImageImg} />
              </div>
              <div className={style.cardValuesMobile}>
                <CardValues product={product} isTablet={isTablet} useAsterisk={useAsterisk} />
              </div>
            </div>
            <TickLines.CreditCard product={product} learnMore={learnMore} isTablet={isTablet} isTopPick={isTopPick} />
            {offersList.length > 0 ? <div className={style.offersListMobile}>{offersList}</div> : null}
            {learnMore ? (
              <div>
                <Clickable.Text
                  style={{ width: "100%", height: "56px", marginTop: "20px", marginBottom: "30px" }}
                  component={Link}
                  to={`/${product.urlSlug}`}
                  onClick={() => {
                    if (isTopPick) {
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_TOP_PICK_SELECTED, { productId: product.id })
                    } else if (product.forSale !== "Y") {
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_PRODUCT_SELECTED, { productId: product.id, forSale: product.forSale })
                    } else {
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_PRODUCT_SELECTED, { productId: product.id })
                    }
                  }}
                  secondary
                >
                  <p>
                    Learn More<span style={{ display: "none" }}>{" about the " + product.cardHeading}</span>
                  </p>
                </Clickable.Text>
              </div>
            ) : null}
          </div>
        </LazyLoad>
      </div>
    )
  } else {
    return (
      <div>
        {makeSchemaOrg(product)}
        <LazyLoad height={400}>
          <div className={style.upperContainer}>
            <div style={{ display: "flex", marginTop: "30px" }}>
              {hideName ? null : (
                <Typography.P comp="card-name-bold" style={{ width: "87.54%" }}>
                  <span style={{ lineHeight: "29.23px", fontSize: "18px" }}>{product.nameLong}</span>
                </Typography.P>
              )}
              <div className="show-for-mobile" style={{ width: "12.46%" }}>
                <LazyLoadImage src={providerImage(product.providerId)} alt={product.providerName} title={product.providerName} style={{ height: "29.23px", width: "42px", marginLeft: "auto", marginRight: "auto", display: "block" }} />
              </div>
            </div>
            {product.forSale === "GRANDFATHERED" && !hideName ? <div className={style.subHeading}>(no longer for sale)</div> : null}
            {product.forSale === "PAUSED" && !hideName ? <div className={style.subHeading}>(currently not for sale)</div> : null}
            <div className={style.cardRow} style={{ marginBottom: largeCardImage ? "54px" : "40px" }}>
              <div className={largeCardImage ? style.cardImageLarge : style.cardImage}>
                <LazyLoadImage src={cardImage(product.id)} title={product.cardHeading} alt={product.cardHeading} className={style.cardImageImg} />
              </div>
              <div className="show-for-desktop" style={{ width: "8.3%" }}></div>
              <div className={style.cardValues}>
                <CardValues product={product} useAsterisk={useAsterisk} />
              </div>
              <div className="show-for-desktop" style={{ width: "16.7%" }}>
                <LazyLoadImage src={providerImage(product.providerId)} alt={product.providerName} title={product.providerName} style={{ height: "53px", width: "77px", marginLeft: "auto", marginRight: "auto", display: "block", marginTop: "17.5px" }} />
              </div>
            </div>
            <TickLines.CreditCard product={product} learnMore={learnMore} isTopPick={isTopPick} />
            {offersList.length > 0 ? <div className={style.offersList}>{offersList}</div> : null}
            {learnMore ? (
              <div className="show-for-mobile">
                <Clickable.Text
                  style={{ width: "100%", height: "56px", marginTop: "20px", marginBottom: "30px" }}
                  component={Link}
                  to={`/${product.urlSlug}`}
                  onClick={() => {
                    if (isTopPick) {
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_TOP_PICK_SELECTED, { productId: product.id })
                    } else if (product.forSale !== "Y") {
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_PRODUCT_SELECTED, { productId: product.id, forSale: product.forSale })
                    } else {
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_PRODUCT_SELECTED, { productId: product.id })
                    }
                  }}
                  secondary
                >
                  <p>
                    Learn More<span style={{ display: "none" }}>{" about the " + product.cardHeading}</span>
                  </p>
                </Clickable.Text>
              </div>
            ) : null}
          </div>
        </LazyLoad>
      </div>
    )
  }
}

export default ProductComponent
