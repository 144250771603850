// import CardValuesHeader from "components/content/card-values-header"
import { CardValuesHeader } from "components/content/product-values-header"
import { cardImage } from "components/survey/utils"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import Product from "models/Product"
import Provider from "models/Provider"
import React from "react"
import { JsonLd } from "react-schemaorg"
import { BreadcrumbList } from "schema-dts"
import AppManager from "services/app-manager"
import { CreditCardDisclaimer } from "../../components/content/content-disclaimer"
import { CreditCard as GetStartedButton } from "../../components/content/get-started-button"
import { ListHeaderProduct } from "components/content/list-header"
import ProductComponent from "../../components/content/product-component"
import { CreditCard as ProductDetails } from "../../components/content/product-details"
import Layout from "../../components/layout"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import * as styles from "./style.module.scss"
import CreditCardProduct from "models/product-models/CreditCardProduct"
import CreditCardProvider from "models/provider-models/CreditCardProvider"
import ReturnLinkChain from "components/content/return-link-chain"
import { CardAds as Ads } from "components/content/ads"
import { useMediaQuery } from "@mui/material"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const BoldText = ({ children }: { children: any }) => {
  return <span className={styles.boldText}>{children}</span>
}

function makeProductComponent(product: Product, key: number, isLast?: boolean) {
  return (
    <div key={key} style={{ borderBottom: isLast ? "" : "0.5pt #D2D2D2 solid" }}>
      <ProductComponent product={product as CreditCardProduct} learnMore />
    </div>
  )
}

function makeReviewComponent(reviews: string[], p3Array: { [id: string]: any }[]): JSX.Element {
  return (
    <div>
      {reviews.map((review, key) => (
        <Typography.P style={{ marginBottom: "15px" }}>
          {review}{" "}
          {key > 1
            ? p3Array.map(p3 => (
                <div style={{ marginBlock: "20px" }}>
                  <div style={{ fontWeight: "bold", color: "#E7527A", whiteSpace: "normal" }}>{p3.offerTitle}</div>
                  {p3.offerDescription}
                  {p3.offerDescription !== "" && p3.offer.active === "Y" && p3.offer.landingPageUrl ? (
                    <span
                      style={{ textDecoration: "none", color: "#4f8ccc", cursor: "pointer" }}
                      onClick={() => {
                        DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: p3.offer.offerId, providerId: p3.offer.providerId, expertReview: true })
                        AppManager.getInstance().getOffer("Go", p3.offer.offerId, p3.offer, p3.offer.providerId, p3.providerName)
                      }}
                    >
                      here.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))
            : ""}
        </Typography.P>
      ))}
    </div>
  )
}

const CardTemplate = (props: any) => {
  const { providerJson, productId, dateUpdated, ads }: { providerJson: Provider; productId: string; dateUpdated: string; ads: any } = JSON.parse(props.pageContext.p)
  const provider: Provider = CreditCardProvider.Initialise(providerJson)
  const product: CreditCardProduct = CreditCardProduct.Initialise(provider.products[productId] as CreditCardProduct)
  const nameHeading: string = product.productCategoryId === "BNPL" ? product.nameLong + " - Buy Now Pay Later" : product.cardHeading
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)

  const isWide = useMediaQuery("(min-width:768px)")

  // Expert Review, TODO: refactor after adding in review
  let expertReviewSection: string | JSX.Element = ""
  // const blacklist = [
  //     "CommBank Neo Card ($1,000 limit)",
  //     "CommBank Neo Card ($2,000 limit)",
  //     "CommBank Neo Card ($3,000 limit)",
  //     "CommBank StepPay",
  //     "NAB StraightUp Card ($1,000 limit)",
  //     "NAB StraightUp Card ($2,000 limit)",
  //     "NAB StraightUp Card ($3,000 limit)",
  //     "Westpac Flex",
  //     "Latitude 28º Global Platinum",
  //     "American Express Low Rate",
  //     "Qantas American Express Discovery",
  //     "Qantas American Express Premium",
  //     "Qantas American Express Ultimate",
  //     "David Jones American Express - Qantas",
  //     "David Jones American Express Platinum - Qantas",
  //     "American Express Velocity Escape",
  //     "American Express Velocity Platinum",
  //     "American Express Essential",
  //     "American Express Explorer",
  //     "American Express Platinum Edge",
  //     "David Jones American Express",
  //     "David Jones American Express Platinum",
  //     "American Express Cashback",
  //     "Latitude Gem Visa",
  //     "Latitude GO Mastercard"
  // ]
  const blacklist = [20000610, 20000620, 20000630, 20000640, 30000510, 30000520, 30000530, 40000800, 60000100, 80000100, 80010100, 80010200, 80010300, 80010400, 80010500, 80040100, 80040200, 80040400, 80041100, 80041200, 80050100, 390000500, 390000600]
  const blacklistStrings = blacklist.map(x => x.toString())
  if (!blacklistStrings.includes(product.id)) {
    expertReviewSection = (
      <div>
        {/* <div className={styles.listHeader}>
          <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "22px" }}>Expert Review</div>
        </div> */}
        <ListHeaderProduct>Expert Review</ListHeaderProduct>

        {product.getExpertReview(provider, makeReviewComponent)}
        {/* {product.getExpertReview(provider)} */}
      </div>
    )
  }

  function makeSchemaOrg(product: Product, provider: Provider, nameHeading: string): JSX.Element {
    return (
      <div>
        <JsonLd<BreadcrumbList>
          item={{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Credit Cards",
                item: "https://www.stayorgo.com.au/credit-cards",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: product.providerName,
                item: "https://www.stayorgo.com.au/" + provider.urlSlug + "-credit-cards",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: nameHeading,
                item: "https://www.stayorgo.com.au/" + product.urlSlug,
              },
            ],
          }}
        />
        {/* <JsonLd<CreditCard>
          item={{
            "@context": "https://schema.org",
            "@type": "CreditCard",
            name: seoName,
            description: description,
          }}
        /> */}
      </div>
    )
  }

  let breadcrumbName = product.name.replace(provider.name, "") || product.name

  return (
    <Layout title={`${nameHeading} - Review | Stay or Go`} description={product.autoDescription} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated}>
      <div className="page-container">
        <div className="content-provider-container">
          {/* <div style={{ marginBottom: "20px", display: "flex" }}>
            <Link
              style={{ textDecoration: "none" }}
              to="/credit-cards/"
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.BROWSER_BACK, { breadcrumbs: true })
              }}
            >
              <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
                {"Credit Cards"}
              </Typography.P>
            </Link>
            &nbsp;
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {"/"}
            </Typography.P>
            &nbsp;
            <Link
              style={{ textDecoration: "none" }}
              to={"/" + provider.urlSlug + "-credit-cards"}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.BROWSER_BACK, { breadcrumbs: true })
              }}
            >
              <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
                {provider.name}
              </Typography.P>
            </Link>
          </div> */}
          <ReturnLinkChain
            linkChain={[
              { name: "Credit Cards", to: "/credit-cards/" },
              { name: provider.name, to: "/" + provider.urlSlug + "-credit-cards" },
            ]}
            currentLocationItem={{ name: breadcrumbName }}
            excludeBottomMargin={isWide}
            includeFactCheckedDialog={isWide}
            factCheckedDialogDateUpdated={dateString}
          />
          <div className={styles.heading}>
            {makeSchemaOrg(product, provider, nameHeading)}
            <Typography.H1>{nameHeading}</Typography.H1>
          </div>
          {product.forSale === "GRANDFATHERED" ? <div className={styles.subHeading}>(no longer for sale)</div> : null}
          {product.forSale === "PAUSED" ? <div className={styles.subHeading}>(currently not for sale)</div> : null}
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {product.autoDescription}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          {ads ? <Ads ads={ads} /> : null}
          <div className="show-for-desktop">
            <ProductComponent product={product} largeCardImage hideName showDisclaimer />
          </div>
          <div className="show-for-mobile">
            <ProductComponent product={product} largeCardImage mobileProduct showDisclaimer />
          </div>
          <div className={styles.getStarted}>
            <div style={{ width: "auto" }}>
              <div style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Arboria-Bold, sans-serif", color: "#E7527A", marginBottom: "11px" }}>Already have a credit card?</div>
              <Typography.P comp={"content-p"}>
                See if there's a better deal in <BoldText>60 Seconds</BoldText>
              </Typography.P>
            </div>
            <div className={styles.getStartedButton}>
              <GetStartedButton product={product} provider={provider} />
            </div>
          </div>
          {product.afFreq === "P1M" || product.rewardsFee !== null || product.directRewardId || product.hasOfferAsterix() ? (
            <Typography.P className={styles.seeDetailsBelow} comp={"flat-18"}>
              * See details below
            </Typography.P>
          ) : null}
          {expertReviewSection}
          {/* <div className={styles.listHeader}>
            <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "22px" }}>Product Details</div>
          </div> */}
          <ListHeaderProduct>Product Details</ListHeaderProduct>
          {product.description !== null ? (
            <div style={{ marginBottom: "30px", marginTop: "30px" }}>
              <div style={{ color: "#797979", fontFamily: "Arboria-Book, sans-serif", fontSize: "16px", display: "flex" }}>
                <img alt="" src="/images/icon_quote.png" style={{ width: "20px", height: "15.66px", marginRight: "15px" }} />
                <div>{product.description}"</div>
              </div>
              <div style={{ color: "#E7527A", fontFamily: "Arboria-Bold, sans-serif", textAlign: "right", fontSize: "16px" }}>- {provider.name}</div>
            </div>
          ) : null}
          <ProductDetails product={product} provider={provider} />
          <div className={styles.cardValuesHeader} style={{ marginTop: "60px", marginBottom: "0px", backgroundColor: "#FBFCFC", paddingTop: "11px", paddingBottom: "27px", height: "60px" }}>
            <CardValuesHeader />
          </div>
          {provider.getAlternativeProductList(product, makeProductComponent).length > 0 ? (
            // <div className={styles.listHeader} style={{ marginTop: "0px" }}>
            //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "22px" }}>Alternative Credit Cards</div>
            // </div>
            <ListHeaderProduct style={{ marginTop: "0px" }}>Alternative Credit Cards</ListHeaderProduct>
          ) : null}
          {provider.getAlternativeProductList(product, makeProductComponent)}
          {product.rewardProgramId !== 0 ? <CreditCardDisclaimer isDisclaimerOpen /> : null}
        </div>
      </div>
    </Layout>
  )
}

export default CardTemplate
