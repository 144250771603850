// extracted by mini-css-extract-plugin
export var cardImage = "style-module--card-image--ac854";
export var cardImageImg = "style-module--card-image-img--1baee";
export var cardImageLarge = "style-module--card-image-large--0a735";
export var cardImageMobile = "style-module--card-image-mobile--bf863";
export var cardRow = "style-module--card-row--ac5e3";
export var cardRowMobile = "style-module--card-row-mobile--9131d";
export var cardValues = "style-module--card-values--d862f";
export var cardValuesMobile = "style-module--card-values-mobile--55a11";
export var offersList = "style-module--offers-list--79a36";
export var offersListMobile = "style-module--offers-list-mobile--0e373";
export var subHeading = "style-module--sub-heading--83251";
export var upperContainer = "style-module--upper-container--8e4d9";