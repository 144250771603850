import { useMediaQuery } from "@mui/material"
import * as Clickable from "components/clickable"
import React from "react"
import * as style from "./style.module.scss"

export const CardValuesHeader = (props: { forFilter?: boolean; setSort?: any; sort?: string }) => {
  return (
    <ProductValuesHeader
      firstValue={<div>account<br/>fees</div>}
      secondValue={<div>purchase<br/>rate</div>}
      thirdValue={<div>points per<br/>$1 spend*</div>}
      firstSortValue={"Account Fees"}
      secondSortValue={"Purchase Rate"}
      thirdSortValue={"Points per $1 Spend"}
      defaultSort={"Product Category"}
      forFilter={props.forFilter}
      setSort={props.setSort}
      sort={props.sort}
      spaceBefore
    />
  )
}

export const LoanValuesHeader = (props: { forFilter?: boolean; setSort?: any; sort?: string }) => {
  return (
    <ProductValuesHeader
      firstValue={<div>interest<br/>rate</div>}
      secondValue={<div>comparison<br/>rate*</div>}
      firstSortValue={"Interest Rate"}
      secondSortValue={"Comparison Rate"}
      defaultSort={"Interest Rate"}
      forFilter={props.forFilter}
      setSort={props.setSort}
      sort={props.sort}
      spaceBefore
    />
  )
}

export const PersonalLoanValuesHeader = (props: { forFilter?: boolean; setSort?: any; sort?: string }) => {
  return (
    <ProductValuesHeader
      firstValue={<div>interest<br/>rate</div>}
      secondValue={<div>comparison<br/>rate</div>}
      thirdValue={<div>monthly<br/>repayment</div>}
      firstSortValue={"Interest Rate"}
      secondSortValue={"Comparison Rate"}
      thirdSortValue={"Monthly Repayment"}
      defaultSort={"Interest Rate"}
      forFilter={props.forFilter}
      setSort={props.setSort}
      sort={props.sort}
    />
  )
}

export const TermDepositValuesHeader = (props: { forFilter?: boolean; setSort?: any; sort?: string }) => {
  return (
    <ProductValuesHeader
      firstValue={<div>term</div>}
      secondValue={<div>interest<br/>rate</div>}
      thirdValue={<div>min.<br/>deposit</div>}
      firstSortValue={"Term"}
      secondSortValue={"Interest Rate"}
      thirdSortValue={"Interest Rate"}
      defaultSort={"Interest Rate"}
      forFilter={props.forFilter}
      setSort={props.setSort}
      sort={props.sort}
    />
  )
}

export const TransactionAccountValuesHeader = (props: { forFilter?: boolean; setSort?: any; sort?: string }) => {
  return (
    <ProductValuesHeader
      firstValue={<div>account<br/>fees</div>}
      secondValue={<div>interest<br/>rate^</div>}
      thirdValue={<div>FX<br/>fees*</div>}
      firstSortValue={"Account Fees"}
      secondSortValue={"Interest Rate"}
      thirdSortValue={"FX Fees"}
      defaultSort={"Account Fees"}
      forFilter={props.forFilter}
      setSort={props.setSort}
      sort={props.sort}
      spaceBefore
    />
  )
}

export const SavingsAccountValuesHeader = (props: { forFilter?: boolean; setSort?: any; sort?: string }) => {
  return (
    <ProductValuesHeader
      firstValue={<div>max variable<br/>rate*</div>}
      secondValue={<div>standard variable<br/>rate</div>}
      firstSortValue={"Max Variable Rate"}
      secondSortValue={"Standard Variable Rate"}
      defaultSort={"Max Variable Rate"}
      forFilter={props.forFilter}
      setSort={props.setSort}
      sort={props.sort}
    />
  )
}

const ProductValuesHeader = (props: {
  firstValue: JSX.Element;
  secondValue: JSX.Element;
  thirdValue?: JSX.Element;
  firstSortValue: string;
  secondSortValue: string;
  thirdSortValue?: string;
  defaultSort: string;
  forFilter?: boolean;
  setSort?: any;
  sort?: string;
  spaceBefore?: boolean;
}) => {
  const isWide = useMediaQuery("(min-width:768px)")
  const hasThirdValue = props.thirdSortValue != null

  const handleSort = (sortName: string) => {
    if (props.sort === sortName) {
      props.setSort(props.defaultSort)
    } else {
      props.setSort(sortName)
    }
  }

  const divWidth = hasThirdValue ? (
    props.spaceBefore ? "25%" : "33.333%"
  ) : (
    props.spaceBefore ? "33.333%" : "50%"
  )

  if (props.forFilter && props.setSort) {
    return (
      <div className={style.productDetailsHeaderFilter}>
        {props.spaceBefore && <div className={style.column} style={{ height: "100%", width: divWidth, borderRight: "0.5px solid #D2D2D2" }}/>}
        <Clickable.View onClick={() => handleSort(props.firstSortValue)} style={{ height: "100%", width: divWidth, borderRight: "0.5px solid #D2D2D2" }}>
          <div className={style.column}>
            {props.firstValue}
          </div>
        </Clickable.View>
        <Clickable.View onClick={() => handleSort(props.secondSortValue)} style={{ height: "100%", width: divWidth, borderRight: hasThirdValue ? "0.5px solid #D2D2D2" : "" }}>
          <div className={style.column}>
            {props.secondValue}
          </div>
        </Clickable.View>
        {hasThirdValue ? (
          <Clickable.View onClick={() => handleSort(props.thirdSortValue as string)} style={{ height: "100%", width: divWidth }}>
            <div className={style.column}>
              {props.thirdValue}
            </div>
          </Clickable.View>
        ) : null}
      </div>
    )
  } else {
    return (
      <div className={!isWide ? style.productDetailsHeaderMobile : style.productDetailsHeader}>
        {props.spaceBefore && <div className={style.column} style={{ height: "100%", width: divWidth, borderRight: "0.5px solid #D2D2D2" }}/>}
        <Clickable.View onClick={() => handleSort(props.firstSortValue as string)} style={{ height: "100%", width: divWidth, borderRight: "0.5px solid #D2D2D2" }}>
          <div className={style.column}>
            {props.firstValue}
          </div>
        </Clickable.View>
        <Clickable.View onClick={() => handleSort(props.secondSortValue as string)} style={{ height: "100%", width: divWidth, borderRight: hasThirdValue ? "0.5px solid #D2D2D2" : undefined }}>
          <div className={style.column}>
            {props.secondValue}
          </div>
        </Clickable.View>
        {hasThirdValue ? (
          <Clickable.View onClick={() => handleSort(props.thirdSortValue as string)} style={{ height: "100%", width: divWidth }}>
            <div className={style.column}>
              {props.thirdValue}
            </div>
          </Clickable.View>
        ) : null}
      </div>
    )
  }
}

export default ProductValuesHeader
