import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { cardImage, providerImage } from "../../survey/utils.js"
import ReactMarkdown from "markdown-to-jsx"
import { LazyLoadImage } from "react-lazy-load-image-component"
import CreditCardProduct from "models/product-models/CreditCardProduct"
import CreditCardOffer from "models/offer-models/CreditCardOffer"
import { Link } from "gatsby"
import * as Clickable from "components/clickable"
import * as style from "./style.module.scss"
import AppManager from "services/app-manager"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import TermDepositProduct, { rate as TermDepositRate } from "models/product-models/TermDepositProduct"
import TermDepositOffer from "models/offer-models/TermDepositOffer"
import AccountProduct, { rate as SavingsAccountRate, rateTier as SavingsAccountRateTier } from "models/product-models/AccountProduct"
import AccountOffer from "models/offer-models/AccountOffer"

type AdsType = {
  offerId: string
  offerHeading: string
  offerBullets: {
    offerBullets: string
  }
  disclaimer: {
    disclaimer: string
  }
}

type CardAdsType = AdsType & {
  product: CreditCardProduct
  offer: CreditCardOffer
}

export const CardAds = (props: { ads: CardAdsType }) => {

  const ads = props.ads
  const isMobile = !useMediaQuery("(min-width:768px)")

  return (

    <div className={style.adsContainer}>
      <div className={style.adsIconContainer}>
        <div>Promoted Offer</div>
      </div>
      <div className={style.adsTitle}>
        <div style={{ width: isMobile ? "50%" : "100%", marginRight: isMobile ? "30px" : "0px" }}>
          {ads.product.nameLong}
        </div>
        {isMobile ? (
          <div style={{ width: "34%" }}>
            <LazyLoadImage src={cardImage(ads.product.id)} style={{ width: "100%", display: "block", marginLeft: "auto" }} />
          </div>
        ) : null}
      </div>
      <div className={style.adsBody}>
        <div style={{ width: isMobile ? "100%" : "66%" }}>
          <div className={style.adsOfferHeading}>{ads.offerHeading}</div>
          <ReactMarkdown children={ads.offerBullets.offerBullets} style={{lineHeight: "1.25"}} />
        </div>
        {!isMobile ? (
          <div style={{ width: "34%" }}>
            <LazyLoadImage src={cardImage(ads.product.id)} style={{ width: "85%", display: "block", marginLeft: "auto" }} />
          </div>
        ) : null}
      </div>
      <div className={style.adsButtonContainer}>
        <Clickable.Text
          className={style.adsButton}
          onClick={() => {
            DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: ads.offer.offerId, providerId: ads.offer.providerId })
            AppManager.getInstance().getOffer("Go", ads.offer.offerId, ads.offer, ads.offer.providerId, ads.product.providerName)
          }}
        >
          Go to offer
        </Clickable.Text>
        <Clickable.Text
          className={style.adsButtonSecondary}
          component={Link}
          to={"/" + ads.product.urlSlug}
          secondary
          noBorder
        >
          <p>Learn More<span style={{ display: "none" }}>{" about the " + ads.product.cardHeading}</span></p>
        </Clickable.Text>
      </div>
      <div className={style.adsDisclaimer}>
        {ads.disclaimer.disclaimer}
      </div>
    </div>
  )
}

type TermDepositAdsType = AdsType & {
  product: TermDepositProduct
  offer: TermDepositOffer | null
}

// TODO: create Ads component for TDs
export const TermDepositAds = (props: { ads: TermDepositAdsType }) => {

  const ads = props.ads
  const isMobile = !useMediaQuery("(min-width:768px)")
  const product = TermDepositProduct.Initialise(ads.product)

  let topRate: TermDepositRate | null = null

  const tdRates = Object.values(product.rates)
  for (let i = 0; i < tdRates.length; i++) {
    const rate = tdRates[i]
    if (rate.interestRate > (topRate?.interestRate || 0) && TermDepositProduct.getTermInMonths(rate.sogMinTerm) <= 12) {
      topRate = rate
    }
  }

  if (!topRate) {
    return (<div/>)
  }

  const minLoanAmount = topRate.minInvestment
  const minLoanTerm = TermDepositProduct.getTermInMonths(topRate.sogMinTerm)
  const appFreq = TermDepositProduct.getTermInMonths(topRate.applicationFreq || "")
  const appFreqString = (topRate.applicationFreq
    ? (
      topRate.applicationFreq === "MATURITY" ? "at maturity"
      : (
        appFreq === 12 ? "annually"
        : (
          appFreq === 1 ? "monthly"
          : `every ${appFreq} months`
        )
      )
    ) : null
  )

  const offer = ads.offer

  return (

    <div className={style.adsContainer}>
      <div className={style.adsIconContainer}>
        <div>Top Pick</div>
      </div>
      <div className={style.adsTitle}>
        <div style={{ width: isMobile ? "50%" : "100%", marginRight: isMobile ? "30px" : "0px" }}>
          {product.nameLong}
        </div>
        <div style={{ width: "80px" }}>
          <LazyLoadImage src={providerImage(product.providerId)} style={{ width: "100%", display: "block", marginLeft: "auto" }} />
        </div>
      </div>
      <div className={style.adsOfferHeading} style={{color: '#00AFC5', fontFamily: 'Arboria-Book, sans-serif'}}>
        <span className={style.large}>{(topRate.interestRate * 100).toFixed(2)}%</span>
        <span> {appFreq === 1 ? 'p.m.' : 'p.a.'} for </span>
        <strong>{minLoanTerm} month{minLoanTerm !== 1 && "s"}</strong>
      </div>
      <div className={isMobile ? undefined : style.adsContent}>
        <div className={style.adsBody} style={{width: isMobile ? '100%' : '75%', margin: '0px'}}>
          <div style={{ marginRight: isMobile ? undefined : '10px' }}>
            <p>{appFreqString && `Interest paid ${appFreqString}`}</p>
            <p>${minLoanAmount.toLocaleString('en-US')} minimum deposit</p>
          </div>
        </div>
        <div className={style.adsButtonContainer} style={{width: isMobile ? '100%' : '25%'}}>
          {offer ? (
            <Clickable.Text
              className={style.adsButtonWide}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: offer.offerId, providerId: offer.providerId })
                AppManager.getInstance().getOffer("Go", offer.offerId, offer, offer.providerId, product.providerName)
              }}
            >
              Go to offer
            </Clickable.Text>
          ) : (
            <Clickable.Text
              className={style.adsButtonSecondaryWide}
              component={Link}
              to={"/" + product.urlSlug}
              secondary
              noBorder
            >
              <p>Learn More</p>
            </Clickable.Text>
          )}
        </div>
      </div>
      {ads.disclaimer.disclaimer && <div className={style.adsDisclaimer}>
        {ads.disclaimer.disclaimer}
      </div>}
    </div>
  )
}

type SavingsAccountAdsType = AdsType & {
  product: AccountProduct
  offer: AccountOffer | null
}

export const SavingsAccountAds = (props: { ads: SavingsAccountAdsType }) => {

  const ads = props.ads
  const isMobile = !useMediaQuery("(min-width:768px)")

  const product = AccountProduct.Initialise(ads.product)

  const rateTiers = Object.values(product.getRatesByDepositTiers())
  let maxRate = 0
  let topTier: SavingsAccountRateTier | null = null
  let topTierHasBonus = false
  let topTierHasIntro = false

  for (let i = 0; i < rateTiers.length; i++) {
    const tier = rateTiers[i]
    let subtotal = 0
    let tierHasBonus = false
    let tierHasIntro = false
    tier.includedRates.forEach(r => {
      subtotal += r.interestRate
      if (r.rateCategory === "BONUS") {
        tierHasBonus = true
      } else if (r.rateCategory === "INTRO") {
        tierHasIntro = true
      }
    })
    if (subtotal > maxRate) {
      maxRate = subtotal
      topTier = tier
      topTierHasBonus = tierHasBonus
      topTierHasIntro = tierHasIntro
    }
  }

  if (!topTier) {
    return (<div/>)
  }

  let introTerm = 0
  if (topTierHasIntro) {
    topTier.includedRates.forEach((r) => {
      if (r.rateCategory === "INTRO") {
        introTerm = AccountProduct.getTermInMonths(r.introPeriod || '')
      }
    })
  }

  const noMonthlyFee = (product.getMonthlyAccountFee() === 0)

  const offer = ads.offer

  return (

    <div className={style.adsContainer}>
      <div className={style.adsIconContainer}>
        <div>Top Pick</div>
      </div>
      <div className={style.adsTitle}>
        <div style={{ width: isMobile ? "50%" : "100%", marginRight: isMobile ? "30px" : "0px" }}>
          {product.nameLong}
        </div>
        <div style={{ width: "80px" }}>
          <LazyLoadImage src={providerImage(product.providerId)} style={{ width: "100%", display: "block", marginLeft: "auto" }} />
        </div>
      </div>
      <div className={style.adsOfferHeading} style={{color: '#00AFC5', fontFamily: 'Arboria-Book, sans-serif'}}>
        <span className={style.large}>{(maxRate * 100).toFixed(2)}%</span>
        <span> p.a. {topTierHasIntro ? (
          <span>for <strong>{introTerm} month{introTerm > 1 ? 's' : ''}</strong></span>
        ) : 'variable interest'}</span>
        {topTierHasBonus && <span>*</span>}
      </div>
      <div className={isMobile ? undefined : style.adsContent}>
        <div className={style.adsBody} style={{width: isMobile ? '100%' : '75%', margin: '0px'}}>
          <div style={{ marginRight: isMobile ? undefined : '10px' }}>
            {topTierHasIntro && <p>Introductory variable interest rate</p>}
            {topTierHasBonus && <p>*when you meet certain criteria</p>}
            {!topTierHasBonus && noMonthlyFee && <p>No monthly fee</p>}
          </div>
        </div>
        <div className={style.adsButtonContainer} style={{width: isMobile ? '100%' : '25%'}}>
          {offer ? (
            <Clickable.Text
              className={style.adsButtonWide}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: offer.offerId, providerId: offer.providerId })
                AppManager.getInstance().getOffer("Go", offer.offerId, offer, offer.providerId, product.providerName)
              }}
            >
              Go to offer
            </Clickable.Text>
          ) : (
            <Clickable.Text
              className={style.adsButtonSecondaryWide}
              component={Link}
              to={"/" + product.urlSlug}
              secondary
              noBorder
            >
              <p>Learn More</p>
            </Clickable.Text>
          )}
        </div>
      </div>
      {ads.disclaimer.disclaimer && <div className={style.adsDisclaimer}>
        {ads.disclaimer.disclaimer}
      </div>}
    </div>
  )
}